import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Tag } from 'hds-react';
import PlaygroundPreview from '../../../components/Playground';
import TabsLayout from './tabs.mdx';
import InternalLink from '../../../components/InternalLink';
export const _frontmatter = {
  "slug": "/components/tag",
  "title": "Tag",
  "navTitle": "Tag"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}<a parentName="h2" {...{
        "href": "#usage",
        "aria-label": "usage permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "example",
      "style": {
        "position": "relative"
      }
    }}>{`Example`}<a parentName="h3" {...{
        "href": "#example",
        "aria-label": "example permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <PlaygroundPreview mdxType="PlaygroundPreview">
  <Tag mdxType="Tag">News</Tag>
  <Tag style={{
        marginLeft: 'var(--spacing-s)'
      }} mdxType="Tag">Announcements</Tag>
    </PlaygroundPreview>
    <h3 {...{
      "id": "principles",
      "style": {
        "position": "relative"
      }
    }}>{`Principles`}<a parentName="h3" {...{
        "href": "#principles",
        "aria-label": "principles permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Tags are meant for presenting object attributes and filters.`}</strong>{` If you are presenting status information, use `}<InternalLink href="/components/status-label" mdxType="InternalLink">{`Status label component`}</InternalLink>{` instead.`}</li>
      <li parentName="ul">{`Keep labels short and concise. Use 1-2 words at maximum and do not use labels expanding to two rows.`}</li>
      <li parentName="ul">{`Do not use icons inside Tag labels.`}</li>
      <li parentName="ul">{`Tags are often related to some other element on the page (e.g. table row or search field as filters). Aim to keep tags and their related elements close to each other so the user can easily interpret the relation.`}</li>
      <li parentName="ul">{`Unlike status labels, `}<strong parentName="li">{`tags can be configured to be clickable and deletable`}</strong>{`. You can use this feature to create removable filters/selections or links to category filtered pages.`}</li>
    </ul>
    <h3 {...{
      "id": "variations",
      "style": {
        "position": "relative"
      }
    }}>{`Variations`}<a parentName="h3" {...{
        "href": "#variations",
        "aria-label": "variations permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <h4 {...{
      "id": "default",
      "style": {
        "position": "relative"
      }
    }}>{`Default`}<a parentName="h4" {...{
        "href": "#default",
        "aria-label": "default permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`By default, Tags are non-interactable elements. They only include a label and do not have any specific styling.`}</p>
    <PlaygroundPreview mdxType="PlaygroundPreview">
      <Tag mdxType="Tag">News</Tag>
      <Tag style={{
        marginLeft: 'var(--spacing-s)'
      }} mdxType="Tag">Announcements</Tag>
    </PlaygroundPreview>
    <h4 {...{
      "id": "clickable",
      "style": {
        "position": "relative"
      }
    }}>{`Clickable`}<a parentName="h4" {...{
        "href": "#clickable",
        "aria-label": "clickable permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`Tags can be configured to be interactable by giving them an `}<inlineCode parentName="p">{`onClick`}</inlineCode>{` function prop. This way Tags can act as links or actions. Since these Tags are fully interactable they can be also focused and receive default HDS focus indicator styling.`}</p>
    <p>{`Remember to add descriptive `}<inlineCode parentName="p">{`aria-label`}</inlineCode>{` to clearly indicate the link target or action to assistive technologies.`}</p>
    <PlaygroundPreview mdxType="PlaygroundPreview">
      <Tag role="link" onClick={() => {}} aria-label="Move to category: News" mdxType="Tag">News</Tag>
      <Tag role="link" onClick={() => {}} aria-label="Move to category: Announcements" style={{
        marginLeft: 'var(--spacing-s)'
      }} mdxType="Tag">Announcements</Tag>
    </PlaygroundPreview>
    <h4 {...{
      "id": "deletable",
      "style": {
        "position": "relative"
      }
    }}>{`Deletable`}<a parentName="h4" {...{
        "href": "#deletable",
        "aria-label": "deletable permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`Tags can be configured to be deletable by giving them an `}<inlineCode parentName="p">{`onDelete`}</inlineCode>{` function prop. This enables a delete icon inside the Tag. Deleting can be useful in situations where Tags are used as filters (e.g. for search).`}</p>
    <p>{`Remember to add descriptive `}<inlineCode parentName="p">{`aria-label`}</inlineCode>{` to the delete button to clearly indicate what the user is deleting when triggering the action.`}</p>
    <PlaygroundPreview mdxType="PlaygroundPreview">
      <Tag onDelete={() => {}} deleteButtonAriaLabel="Delete filter: News" mdxType="Tag">News</Tag>
      <Tag onDelete={() => {}} deleteButtonAriaLabel="Delete filter: Announcements" style={{
        marginLeft: 'var(--spacing-s)'
      }} mdxType="Tag">Announcements</Tag>
    </PlaygroundPreview>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      